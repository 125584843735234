import React from "react";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Carousel from "nuka-carousel";
import {Root} from "../styled";
import {coins} from "./data";
import useCoincapApi from "./../../../hooks/useCoincapApi";

const CryptoSlide = ({cryptoData, loading}) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"), {
        defaultMatches: true
    });

    return (
        <Root>
            <Container>
                <TitleBox>
                    <CryptoTitle>Top Cryptocurrencies</CryptoTitle>
                </TitleBox>
                <StyledCarouselWrapper isMd={isMd} theme={theme} />
            </Container>
        </Root>
    );
};

export default CryptoSlide;

const StyledCarouselWrapper = ({isMd, theme}) => {
    return (
        <StyledCarousel
            autoplay="true"
            wrapAround="true"
            slidesToShow={isMd ? 1 : 3}
            adaptiveHeight="true"
            cellSpacing={15}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            defaultControlsConfig={{
                pagingDotsStyle: {
                    paddingLeft: 15,
                    fill: theme.palette.primary.main,
                    display: "none"
                }
            }}>
            {coins.map((coin) => {
                return <CryptoContainer coin={coin} key={coin.id} />;
            })}
        </StyledCarousel>
    );
};
const CryptoContainer = ({coin}) => {
    const [data, loading] = useCoincapApi(coin.id);

    function roundToTwo(value) {
        return Number(value).toFixed(2);
    }

    return (
        <CryptoCard key={coin.id}>
            {loading ? (
                <Box
                    sx={{
                        width: "inherit",
                        height: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <CircularProgress />
                </Box>
            ) : (
                <CardContent>
                    <CoinLogo>
                        <CryptoImage src={coin.image} alt="data_img" />
                    </CoinLogo>
                    <CoinName>{data.name}</CoinName>
                    <CoinPrice>{"USD " + roundToTwo(data.priceUsd)}</CoinPrice>
                    <CoinChange>
                        {data.changePercent24Hr?.includes("-") ? (
                            <PriceRed>
                                {roundToTwo(data.percentChange) + "%"}
                            </PriceRed>
                        ) : (
                            <PriceGreen>
                                {"+" + roundToTwo(data.percentChange) + "%"}
                            </PriceGreen>
                        )}
                    </CoinChange>
                </CardContent>
            )}
        </CryptoCard>
    );
};
const TitleBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "left",
    marginBottom: 40
}));
const CryptoTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h4,
    color: theme.palette.text.primary
}));
const StyledCarousel = styled(Carousel)(() => ({
    position: "relative",
    width: "100%"
}));
const CryptoCard = styled(Card)(({theme}) => ({
    padding: "30px 20px",
    margin: 20,
    boxShadow: theme.shadows[11],
    height: 320,
    [theme.breakpoints.down("lg")]: {
        padding: 20
    },
    [theme.breakpoints.down("md")]: {
        padding: "20px"
    },
    [theme.breakpoints.down("sm")]: {
        padding: "20px",
        height: 250
    }
}));
const CoinLogo = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));
const CoinName = styled(Typography)(({theme}) => ({
    ...theme.typography.h6,
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    margin: "20px auto",
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));
const CoinPrice = styled(Typography)(({theme}) => ({
    ...theme.typography.h4,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.text.primary,
    marginBottom: 10,
    [theme.breakpoints.down("lg")]: {
        fontSize: 20
    },
    [theme.breakpoints.down("md")]: {
        fontSize: 18
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));
const CoinChange = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center"
}));
const CryptoImage = styled("img")(({theme}) => ({
    width: 60,
    padding: 10,
    borderRadius: 15,
    boxShadow: theme.customShadows.primary,
    [theme.breakpoints.down("sm")]: {
        width: 40
    }
}));
const PriceRed = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1,
    color: "red",
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const PriceGreen = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1,
    color: "#098551",
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
