import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {Svg} from "../../styled";

function WalletSvg(props) {
    const theme = useTheme();
    const color = theme.palette.primary.dark;
    return (
        <Svg
            height="361pt"
            viewBox="-13 0 361 361.81333"
            fill={color}
            width="361pt"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M7.234 150.16a6.825 6.825 0 01-6.828-6.828c0-41.394 33.672-75.066 75.067-75.066h10.8c3.774 0 6.825 3.054 6.825 6.828s-3.051 6.828-6.825 6.828h-10.8c-33.868 0-61.414 27.547-61.414 61.41a6.822 6.822 0 01-6.825 6.828zm0 0" />
            <path d="M93.426 113.781a6.816 6.816 0 01-6.434-4.554c-3.097-8.754-4.664-17.942-4.664-27.305C82.328 36.746 119.074 0 164.246 0c45.176 0 81.922 36.746 81.922 81.922a81.815 81.815 0 01-4.082 25.59c-1.18 3.582-5.047 5.527-8.621 4.355-3.578-1.18-5.524-5.039-4.352-8.62a68.198 68.198 0 003.399-21.325c0-37.64-30.625-68.27-68.266-68.27S95.98 44.282 95.98 81.922a68.27 68.27 0 003.88 22.758 6.82 6.82 0 01-4.16 8.707c-.75.265-1.52.394-2.274.394zm0 0" />
            <path d="M164.246 54.613a6.822 6.822 0 01-6.824-6.828V34.133a6.818 6.818 0 016.824-6.824 6.822 6.822 0 016.828 6.824v13.652a6.825 6.825 0 01-6.828 6.828zm0 0M298.54 361.813H36.776c-20.05 0-36.37-16.317-36.37-36.372V138.773c0-20.054 16.32-36.375 36.37-36.375H298.54c20.055 0 36.371 16.32 36.371 36.375v186.665c.004 20.058-16.316 36.375-36.37 36.375zM36.776 116.054c-12.523 0-22.718 10.191-22.718 22.718v186.665c0 12.527 10.195 22.722 22.718 22.722H298.54c12.527 0 22.719-10.195 22.719-22.719V138.773c0-12.527-10.195-22.718-22.719-22.718zm0 0" />
            <path d="M328.086 286.719h-78.508c-24.465 0-44.371-19.906-44.371-44.371 0-24.47 19.906-44.375 44.371-44.375h78.508a6.825 6.825 0 016.828 6.828v75.094a6.822 6.822 0 01-6.828 6.824zm-78.508-75.094c-16.937 0-30.719 13.781-30.719 30.723 0 16.937 13.782 30.718 30.72 30.718h71.683v-61.441zm0 0" />
            <path d="M249.578 266.238c-13.172 0-23.89-10.718-23.89-23.89 0-13.176 10.718-23.895 23.89-23.895 13.176 0 23.895 10.719 23.895 23.895 0 13.172-10.72 23.89-23.895 23.89zm0-34.133c-5.644 0-10.238 4.594-10.238 10.243 0 5.644 4.594 10.238 10.238 10.238 5.649 0 10.242-4.594 10.242-10.238 0-5.649-4.593-10.243-10.242-10.243zm0 0M20.887 150.188H7.234c-3.773 0-6.828-3.055-6.828-6.829s3.055-6.824 6.828-6.824h13.653c3.773 0 6.828 3.05 6.828 6.824s-3.055 6.828-6.828 6.828zm0 0M287.746 150.188H261.06c-3.774 0-6.829-3.055-6.829-6.829s3.055-6.824 6.829-6.824h26.687c3.774 0 6.828 3.05 6.828 6.824s-3.054 6.828-6.828 6.828zm-53.371 0h-26.687c-3.774 0-6.829-3.055-6.829-6.829s3.055-6.824 6.829-6.824h26.687c3.773 0 6.824 3.05 6.824 6.824s-3.05 6.828-6.824 6.828zm-53.367 0H154.32c-3.773 0-6.828-3.055-6.828-6.829s3.055-6.824 6.828-6.824h26.688c3.773 0 6.824 3.05 6.824 6.824s-3.05 6.828-6.824 6.828zm-53.375 0h-26.688c-3.773 0-6.824-3.055-6.824-6.829s3.05-6.824 6.824-6.824h26.688c3.773 0 6.828 3.05 6.828 6.824s-3.055 6.828-6.828 6.828zm-53.375 0H47.574c-3.773 0-6.828-3.055-6.828-6.829s3.055-6.824 6.828-6.824h26.688c3.773 0 6.824 3.05 6.824 6.824s-3.055 6.828-6.828 6.828zm0 0M328.086 150.188h-13.652c-3.774 0-6.829-3.055-6.829-6.829s3.055-6.824 6.829-6.824h13.652c3.773 0 6.828 3.05 6.828 6.824s-3.055 6.828-6.828 6.828zm0 0M156.246 109.227V89.914c0-.48.242-.719.715-.719h16.953c7.879 0 12.293 4.54 12.293 11.227 0 3.773-1.406 6.812-3.969 8.805h16.649a28.313 28.313 0 001.406-8.926c0-9.906-5.848-16.352-10.86-18.266v-.242c5.133-2.746 9.426-7.992 9.426-17.184 0-13.015-8.113-21.609-25.304-21.609h-30.2c-.714 0-1.195.473-1.195 1.195v65.032zm0-53.098c0-.473.242-.715.715-.715h16.238c7.16 0 11.582 4.3 11.582 10.746 0 6.328-4.422 10.621-11.582 10.621h-16.238c-.473 0-.715-.242-.715-.715zm0 0" />
        </Svg>
    );
}

export default WalletSvg;
