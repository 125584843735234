import React from "react";
import {styled, useTheme} from "@mui/material/styles";
import {Box, IconButton, List, Toolbar, useScrollTrigger} from "@mui/material";
import {animateScroll as scroll, Link as ScrollLink} from "react-scroll";
import {Icon} from "@iconify/react";
import {ContainedButton, OutlinedButton} from "../styled";
import LogoSvg from "./components/logoSvg";

const Header = ({toggle}) => {
    const theme = useTheme();
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return (
        <Navbar disableGutters scrollNav={trigger}>
            <LogoContainer onClick={toggleHome}>
                <LogoSvg fill={trigger ? theme.palette.primary.main : theme.palette.common.white} width={200}/>
            </LogoContainer>
            <Box sx={{flexGrow: 0.15}}></Box>
            <Box sx={{display: {xs: "none", md: "block"}}}>
                <NavItemWrapper>
                    <NavLinksContainer>
                        {navItems.map((navItem) => (
                            <NavLinks
                                scrollNav={trigger}
                                key={navItem.id}
                                to={navItem.idm}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-100}>
                                {navItem.name}
                            </NavLinks>
                        ))}
                    </NavLinksContainer>
                    <ButtonContainer>
                    {/* /auth/register */}
                        <PageLink href="https://user.koinexchanger.pro/auth/register" scrollNav={trigger}>
                            <OutlinedButton> Sign up </OutlinedButton>
                        </PageLink>
                        <PageLink href="https://user.koinexchanger.pro/auth/login">
                            <ContainedButton>Login</ContainedButton>
                        </PageLink>
                        {/* /auth/login */}
                    </ButtonContainer>
                </NavItemWrapper>
            </Box>
            <Box sx={{display: {xs: "block", md: "none"}}}>
                <NavItemWrapper>
                    <NavIconButton onClick={toggle}>
                        <NavIcon icon="ri:menu-3-fill" scrollNav={trigger}/>
                    </NavIconButton>
                </NavItemWrapper>
            </Box>
        </Navbar>
    );
};
export default Header;

const Navbar = styled(Toolbar, {shouldForwardProp: propName => propName !== "scrollNav"})(({theme, scrollNav}) => ({
    height: 80,
    width: "100%",
    position: "fixed",
    background: scrollNav ? theme.palette.background.default : "transparent",
    boxShadow: scrollNav ? theme.customShadows.z12 : "none",
    top: 0,
    left: 0,
    opacity: scrollNav ? 0.9 : "none",
    transition: ".35s ease-in-out",
    zIndex: 999
}));
const LogoContainer = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginLeft: "10%",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
        marginLeft: "5%"
    }
}));

const NavItemWrapper = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}));
const NavLinksContainer = styled(List)(({theme}) => ({
    display: "flex",
    width: "500px",
    alignItems: "center",
    paddingLeft: 20,
    [theme.breakpoints.down("lg")]: {
        marginLeft: -20
    }
}));
const NavLinks = styled(ScrollLink, {shouldForwardProp: propName => propName !== "scrollNav"})(({theme, scrollNav}) => ({
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    padding: "27.6px 20px",
    color: scrollNav ? theme.palette.text.primary : "#fff",
    cursor: "pointer",
    fontSize: "1rem",
    "&.active": {
        color: scrollNav ? theme.palette.primary.main : "#fff",
        fontWeight: "bold"
    },
    "&:hover": {
        color: scrollNav
            ? theme.palette.primary.light
            : theme.palette.grey[400],
        transition: "0.4s all ease-in-out"
    },
    [theme.breakpoints.down("lg")]: {
        padding: "25px 8px"
    }
}));
const ButtonContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    display: "flex",
    right: "10%",
    [theme.breakpoints.down("lg")]: {
        right: "5%"
    }
}));
const NavIconButton = styled(IconButton)(() => ({
    position: "absolute",
    right: "10%"
}));
const NavIcon = styled(Icon, {shouldForwardProp: propName => propName !== "scrollNav"})(({theme, scrollNav}) => ({
    fontSize: "30px",
    color: scrollNav ? theme.palette.primary.dark : "#fff"
}));
const PageLink = styled("a")(() => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginLeft: 15
    }
}));
export const navItems = [
    {id: 1, idm: "home", name: "Home"},
    {id: 2, idm: "about", name: "About"},
    {id: 3, idm: "features", name: "Features"},
    {id: 4, idm: "faqs", name: "FAQs"},
    {id: 5, idm: "contact", name: "Contact"}
];
