import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {Svg} from "./blob-a";

function BlobB(props) {
    const theme = useTheme();
    const background = theme.palette.primary.light;
    return (
        <Svg
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            {...props}>
            <path
                d="M395.5 348.5Q364 447 263.5 424T98 325.5q-65-75.5 10.5-132t168-103Q369 44 398 147t-2.5 201.5z"
                fill={background}
            />
        </Svg>
    );
}

export default BlobB;
