import React, {useCallback, useMemo} from 'react';
import Particles from "react-particles";
import {loadFull} from "tsparticles";
import {styled} from "@mui/material/styles";

const ParticleEffect = () => {
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
    }, []);

    const options = useMemo(() => ({
        fullScreen: {
            enable: false
        },
        fpsLimit: 60,
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push"
                },
                onHover: {
                    enable: true,
                    mode: "repulse"
                },
                resize: true
            },
            modes: {
                bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40
                },
                push: {
                    quantity: 4
                },
                repulse: {
                    distance: 200,
                    duration: 0.4
                }
            }
        },
        particles: {
            color: "rgba(255, 255, 255, 0.3)",
            links: {
                color: "rgba(255, 255, 255, 0.3)",
                distance: 200,
                enable: true,
                opacity: 0.5,
                width: 1
            },
            move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 3,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 1000
                },
                value: 40
            },
            opacity: {
                value: 0.4
            },
            shape: {
                type: "circle"
            },
            size: {
                random: true,
                value: 5
            }
        }
    }), [])

    return (
        <StyledParticles
            id="tsparticles"
            options={options} init={particlesInit}
            loaded={particlesLoaded}
        />
    );
};

const StyledParticles = styled(Particles)({
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 3
});

export default ParticleEffect;