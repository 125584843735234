import React from "react";
import {CentreWrapper, ColumnWrapper, ContainedButton} from "../styled";
import {Box, Container, Typography} from "@mui/material";
import {Link as LinkS, animateScroll as scroll} from "react-scroll";
import {styled, useTheme} from "@mui/material/styles";
import {Icon} from "@iconify/react";

const Footer = () => {
    const [email, setEmail] = React.useState("");
    const theme = useTheme();
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <FooterStyle sx={{bgcolor: theme.palette.primary.main}}>
            <Container maxWidth="lg">
                <FooterRow>
                    <FooterLogoContainer>
                        <FooterLogo to="/" onClick={toggleHome}>
                            KoinExchanger
                        </FooterLogo>
                        <IconWrapper>
                            <IconLink href="https://facebook.com">
                                <FooterIcon icon="bx:bxl-facebook"/>
                            </IconLink>
                            <IconLink href="https://instagram.com">
                                <FooterIcon icon="ant-design:instagram-outlined"/>
                            </IconLink>
                            <IconLink href="https://x.com">
                                <FooterIcon icon="bi:twitter-x"/>
                            </IconLink>
                        </IconWrapper>
                    </FooterLogoContainer>
                    <ColumnWrapper sx={{width: "100%"}}>
                        <NewsTitle>Subscribe To Our News</NewsTitle>
                        <NewsBox
                            action="https://kointexx.us18.list-manage.com/subscribe/post?u=ab8c776054fcc220f49ee3d54&amp;id=f3046e60a2&amp;f_id=00e8c2e1f0"
                            method="post" target="_blank">
                            <NewsTextBox
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                name="EMAIL"
                                id="mce-EMAIL"
                            />
                            <TextButton
                                type="submit"
                                value="Subscribe"
                                name="subscribe"
                                id="mc-embedded-subscribe">
                                Subscribe
                            </TextButton>
                        </NewsBox>
                    </ColumnWrapper>
                    <FooterLinksContainer>
                        <FooterLinkWrapper>
                            <FooterLinkTitle>Company</FooterLinkTitle>
                            <FooterLinks
                                to="about"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-50}>
                                About us
                            </FooterLinks>
                            <FooterLinks
                                to="features"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-50}>
                                Why KoinExchanger?
                            </FooterLinks>
                        </FooterLinkWrapper>
                        <ColumnWrapper>
                            <FooterLinkTitle>Useful Links</FooterLinkTitle>
                            <FooterLink href="https://user.koinexchanger.pro/auth/register">
                                <LinkIcon icon="akar-icons:bitcoin-fill"/>
                                Buy and sell
                            </FooterLink>
                            <FooterLink href="https://user.koinexchanger.pro/auth/login">
                                <LinkIcon icon="uil:exchange"/>
                                Exchange
                            </FooterLink>
                        </ColumnWrapper>
                    </FooterLinksContainer>
                </FooterRow>
            </Container>
        </FooterStyle>
    );
};

export default Footer;

const FooterStyle = styled(Box)(({theme}) => ({
    width: "100%",
    padding: "100px 0px",
    position: "relative",
    boxSizing: "border-box",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        padding: "40px 0"
    }
}));
const FooterLogoContainer = styled(CentreWrapper)(({theme}) => ({
    width: "100%",
    marginLeft: -50,
    [theme.breakpoints.down("md")]: {
        margin: 0
    }
}));
const FooterLogo = styled(LinkS)(({theme}) => ({
    ...theme.typography.h3,
    cursor: "pointer",
    color: "#fff"
}));
const FooterRow = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center"
    }
}));
const IconWrapper = styled(Box)(() => ({
    display: "flex",
    marginTop: 10
}));
const FooterIcon = styled(Icon)(({theme}) => ({
    fontSize: 35,
    borderRadius: "50%",
    padding: 6,
    background: "rgba(255,255,255, 0.25)",
    cursor: "pointer",
    color: "#fff",
    "&:hover": {
        color: theme.palette.primary.light
    },
    "&:not(:first-of-type)": {
        marginLeft: 10
    }
}));
const IconLink = styled("a")(() => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginLeft: 10
    }
}));
const NewsTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h4,
    color: "#fff",
    marginBottom: 10,
    [theme.breakpoints.down("md")]: {
        margin: "30px auto 10px"
    }
}));
const NewsBox = styled("form")(({theme}) => ({
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
        width: "50%",
        margin: "0 auto"
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));
const NewsTextBox = styled("input")(({theme}) => ({
    outline: "none",
    padding: "0 26% 0px 25px",
    border: "none",
    borderRadius: 5,
    height: 55,
    width: "85%",
    background: "rgba(255,255,255,0.25)",
    transition: ".3s",
    fontSize: 16,
    color: "#fff",
    "&::placeholder": {
        color: "#fff",
        fontFamily: theme.typography.fontFamily
    },
    "&:hover": {
        background: "#fff",
        color: theme.palette.grey[500],
        "&::placeholder": {
            color: theme.palette.grey[500]
        }
    }
}));
const TextButton = styled(ContainedButton)(({theme}) => ({
    background: "#fff",
    color: theme.palette.primary.light,
    position: "absolute",
    borderRadius: theme.shape.borderRadiusLg,
    right: 0,
    top: "6.1%",
    "&:hover": {
        background: "#fff",
        color: theme.palette.primary.dark
    }
}));
const FooterLinksContainer = styled(Box)(({theme}) => ({
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        marginTop: 30
    }
}));
const FooterLinkTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    color: "#fff"
}));
const FooterLinkWrapper = styled(ColumnWrapper)(({theme}) => ({
    marginRight: 50,
    [theme.breakpoints.down("lg")]: {
        marginRight: 40
    }
}));
const FooterLink = styled("a")(({theme}) => ({
    ...theme.typography.body1,
    color: "#fff",
    textDecoration: "none",
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    "&:hover": {
        color: theme.palette.primary.light
    }
}));
const FooterLinks = styled(LinkS)(({theme}) => ({
    ...theme.typography.body1,
    color: "#fff",
    textDecoration: "none",
    marginTop: 10,
    cursor: "pointer",
    "&:hover": {
        color: theme.palette.primary.light
    }
}));
const LinkIcon = styled(Icon)(() => ({
    color: "#fff",
    fontSize: 20,
    marginRight: 10
}));
