import * as React from "react";
import {styled} from "@mui/material/styles";

function Badge(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 128"
            {...props}>
            <path d="M90.8 91.6c.4.1.6.2.9.2.9 0 1.4-.4 1.7-.7.3-.3.7-.9.7-1.8-.1-9.8 6.1-18.3 15.4-21.2.2-.1.5-.2.7-.4.7-.5.9-1.2 1-1.6.1-.5 0-1.2-.5-1.9-5.8-7.8-5.8-18.3 0-26.1l.3-.4c0-.1.1-.2.1-.3.3-.8 0-1.5-.2-1.9-.2-.4-.6-1-1.5-1.2-9.2-2.9-15.4-11.4-15.3-21.1 0-.4 0-.6-.1-.9-.3-.8-.8-1.2-1.3-1.4-.4-.2-1.1-.4-1.9-.1-2.3.8-4.7 1.2-7 1.2-7.1 0-13.8-3.4-17.9-9.2l-.6-.6c-1.1-.8-2.7-.5-3.5.6C57.7 8.6 51 12 44 12c-2.4 0-4.8-.4-7-1.2-.3-.1-.5-.1-.8-.1-.8 0-1.4.4-1.7.7-.3.3-.7.9-.7 1.8.1 9.7-6.1 18.2-15.4 21.1-.3.1-.5.2-.8.4-1.1.8-1.3 2.4-.5 3.5 5.8 7.8 5.8 18.3 0 26.1-.2.2-.3.5-.4.7-.3.8 0 1.5.2 1.9.2.4.7 1 1.5 1.2 9.3 3 15.4 11.5 15.3 21.1 0 .3 0 .6.1.9.3.8.8 1.2 1.3 1.4.4.2 1.1.4 1.9.1 2.4-.8 4.8-1.2 7.1-1.2 7 0 13.6 3.3 17.8 9.2l.6.6c.7.5 1.4.5 1.9.4.5-.1 1.1-.3 1.6-1 5.6-7.9 15.6-11.1 24.8-8zM65.2 75.4c-.5 1.2-2.2 1.2-2.7 0-4.6-10.4-12.8-18.6-22.8-23-1.2-.5-1.2-2.2 0-2.7C50 45.3 58.2 37.2 62.6 26.9c.5-1.2 2.2-1.2 2.7 0 4.4 10.3 12.6 18.5 22.9 22.8 1.2.5 1.2 2.2 0 2.7-10.3 4.7-18.6 13-23 23z" />
            <path d="M38.8 97c-2 .7-4.1.5-6.1-.3l-.2-.1c-1.9-1-3.4-2.6-4.1-4.6-.2-1-.4-2-.3-3 0-3.9-1.3-7.5-3.6-10.3l-20 30.7h17.8l8.3 17L49.7 97c-1.7-.6-3.6-1-5.5-1-1.8.1-3.6.4-5.4 1zm64.7-18.3c-2.4 2.9-3.7 6.5-3.6 10.5 0 2.2-.8 4.3-2.4 5.8-1.4 1.4-3.4 2.3-5.4 2.4-1 .1-2.1-.1-3.2-.5-3.5-1.2-7.2-1.1-10.6.1l19.2 29.4 8.3-17h17.8l-20.1-30.7z" />
        </Svg>
    );
}

export default Badge;

const Svg = styled("svg")(({theme}) => ({
    width: 30,
    height: 20,
    fill: theme.palette.primary.darker
}));
