import {mountHandler} from "../utils/helpers";
import {useEffect, useRef} from "react";

const useMountHandler = () => {
    const handler = useRef(mountHandler());

    useEffect(() => {
        const ref = handler.current;
        return () => ref.unmount();
    }, []);

    return handler.current;
};

export default useMountHandler;
