import {useRef} from "react";
import {isFunction} from "lodash";

export function useVar(value) {
    const ref = useRef(null);

    if (ref.current === null) {
        if (isFunction(value)) {
            ref.current = value();
        } else {
            ref.current = value;
        }
    }

    return ref.current;
}

export function mountHandler() {
    let mounted = true;

    const execute = (callback) => {
        if (mounted) {
            return callback?.();
        }
    };

    const unmount = () => {
        mounted = false;
    };

    return {execute, unmount};
}
