import React, {useState} from "react";
import {Box, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import ThemeConfig from "../theme";
import {
    About,
    Banner,
    Contact,
    CryptoSlide,
    Explore,
    Faq,
    Features,
    Footer,
    Header,
    Register,
    Settings,
    Sidebar
} from "./components";
import PriceSlide from "./components/priceSlide";

const Landing = () => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true
    });

    const open = isMd ? false : isOpen;

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <ThemeConfig>
            <Box sx={{overflow: "hidden"}}>
                <Header toggle={toggle}/>
                <Sidebar open={open} toggle={toggle}/>
                <Banner/>
                <CryptoSlide/>
                <About/>
                <Features/>
                <PriceSlide/>
                <Explore/>
                <Register/>
                <Faq/>
                <Contact/>
                <Footer/>
                <Settings/>
            </Box>
        </ThemeConfig>
    );
};

export default Landing;
