import React from "react";
import {Box, Container, Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {CarouselProvider, Dot, Slide, Slider} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import bannerImage from "../../../assets/images/banner-mockup.png";
import dashboardLightImage from "../../../assets/images/dashboardLight.png";
import dashboardDarkImage from "../../../assets/images/dashboardDark.png";
import paymentLightImage from "../../../assets/images/paymentLight.png";
import paymentDarkImage from "../../../assets/images/paymentDark.png";
import walletLightImage from "../../../assets/images/walletLight.png";
import walletDarkImage from "../../../assets/images/walletDark.png";
import {
    ContainedButton,
    Image,
    ImageContainer,
    OutlinedButton,
    PageLink
} from "../styled";
import {Icon} from "@iconify/react";
import {MotionInView, varFadeInDown, varFadeInUp} from "../animate";
import BlobA from "./components/blob-a";
import BlobB from "./components/blob-b";
import BlobC from "./components/blob-c";
import useSettings from "../../../hooks/useSettings";
import ParticleEffect from "./components/ParticleEffect";

const Banner = () => {
    const {themeMode} = useSettings();
    const isLight = themeMode === "light";

    return (
        <BannerStyle id="home">
            <ParticleEffect />

            <Container maxWidth="lg">
                <Grid container sx={{height: "100%"}}>
                    <Grid item xs={12} md={5} sx={{zIndex: 3}}>
                        <BannerContent>
                            <MotionInView variants={varFadeInDown}>
                                <BannerTitle>Robust. Fast. Secure</BannerTitle>
                                <BannerSubtitle>
                                    Distributing finance for everyone
                                </BannerSubtitle>
                                <BannerBody>
                                    Buy and sell cryptocurrencies with
                                    KoinExchanger. Pay directly from your bank or
                                    with PayPal!
                                </BannerBody>
                            </MotionInView>
                            <MotionInView
                                variants={varFadeInUp}
                                >
                                <BannerButtonContainer>
                                    <PageLink href="https://user.koinexchanger.pro/auth/register">
                                        <BannerContainedButton>
                                            Get Started
                                        </BannerContainedButton>
                                    </PageLink>
                                    <PageLink href="https://user.koinexchanger.pro/auth/login">
                                        <BannerOutlinedButton>
                                            Login
                                        </BannerOutlinedButton>
                                    </PageLink>
                                </BannerButtonContainer>
                            </MotionInView>
                            <ShapeAContainer>
                                <BlobA />
                            </ShapeAContainer>
                        </BannerContent>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{zIndex: 4}}>
                        <BannerImageContainer>
                            <Image src={bannerImage} alt="kointexx-slider " />
                            <BannerCarousel
                                naturalSlideHeight={100}
                                naturalSlideWidth={100}
                                totalSlides={3}
                                infinite={true}
                                isPlaying={true}>
                                <Slider classNameAnimation={animationStyle}>
                                    <Slide index={0}>
                                        {isLight ? (
                                            <CarouselImage
                                                src={dashboardLightImage}
                                            />
                                        ) : (
                                            <CarouselImage
                                                src={dashboardDarkImage}
                                            />
                                        )}
                                    </Slide>
                                    <Slide index={1}>
                                        {isLight ? (
                                            <CarouselImage
                                                src={paymentLightImage}
                                            />
                                        ) : (
                                            <CarouselImage
                                                src={paymentDarkImage}
                                            />
                                        )}
                                    </Slide>
                                    <Slide index={2}>
                                        {isLight ? (
                                            <CarouselImage
                                                src={walletLightImage}
                                            />
                                        ) : (
                                            <CarouselImage
                                                src={walletDarkImage}
                                            />
                                        )}
                                    </Slide>
                                </Slider>
                                <CarouselGroup>
                                    <CarouselDot slide={0} />
                                    <CarouselDot slide={1} />
                                    <CarouselDot slide={2} />
                                </CarouselGroup>
                            </BannerCarousel>
                            <ShapeBContainer>
                                <BlobB />
                            </ShapeBContainer>
                            <ShapeCContainer>
                                <BlobC />
                            </ShapeCContainer>
                        </BannerImageContainer>
                    </Grid>
                </Grid>
            </Container>
            <IconWrapper>
                <BannerLink href="https://facebook.com">
                    <BannerIcon icon="bx:bxl-facebook" />
                </BannerLink>
                <BannerLink href="https://instagram.com">
                    <BannerIcon icon="ant-design:instagram-outlined" />
                </BannerLink>
                <BannerLink href="https://x.com">
                    <BannerIcon icon="bi:twitter-x" />
                </BannerLink>
            </IconWrapper>

            <SideShape></SideShape>
        </BannerStyle>
    );
};
export default Banner;

const BannerStyle = styled(Box)(({theme}) => ({
    background: `linear-gradient(to top right, ${theme.palette.primary.main} 50%, ${theme.palette.primary.light} 100%)`,
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        height: "fit-content"
    }
}));
const BannerContent = styled(Box)(({theme}) => ({
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: "250px 0px 180px",
    [theme.breakpoints.down("md")]: {
        padding: "120px 0px 0px",
        alignItems: "center"
    }
}));
const BannerTitle = styled(Box)(({theme}) => ({
    ...theme.typography.h1,
    color: "#fff",
    marginBottom: 30
}));
const BannerSubtitle = styled(Box)(({theme}) => ({
    ...theme.typography.h4,
    color: "#fff",
    fontWeight: 500,
    marginBottom: 20
}));
const BannerBody = styled(Box)(({theme}) => ({
    ...theme.typography.h6,
    fontWeight: 500,
    color: "#fff"
}));
const BannerButtonContainer = styled(Box)(() => ({
    display: "flex",
    marginTop: 30
}));
const BannerContainedButton = styled(ContainedButton)(({theme}) => ({
    background: theme.palette.primary.dark,
    height: 60,
    width: 180,
    [theme.breakpoints.down("sm")]: {
        height: 48,
        padding: "8px 22px",
        width: "100%"
    },
    "&:hover": {
        background: theme.palette.primary.light
    }
}));
const BannerOutlinedButton = styled(OutlinedButton)(({theme}) => ({
    border: "2px solid #fff",
    color: "#fff",
    height: 60,
    width: 180,
    marginLeft: 20,
    "&:hover": {
        border: `2px solid ${theme.palette.primary.light}`,
        background: theme.palette.primary.light
    },
    [theme.breakpoints.down("sm")]: {
        height: 48,
        padding: "8px 22px",
        width: "100%"
    }
}));
const BannerImageContainer = styled(ImageContainer)(({theme}) => ({
    marginLeft: 50,
    position: "relative",
    marginTop: 250,
    zIndex: 3,
    [theme.breakpoints.down("md")]: {
        margin: " 50px auto 150px"
    },
    [theme.breakpoints.down("sm")]: {
        margin: " 50px auto 30px"
    }
}));
const BannerCarousel = styled(CarouselProvider)(({theme}) => ({
    position: "relative",
    marginTop: "-51%",
    marginLeft: "17%",
    height: 300,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: 250
    }
}));
const CarouselGroup = styled(Box)(({theme}) => ({
    position: "absolute",
    right: "10%",
    bottom: "50%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
        bottom: 0,
        flexDirection: "row",
        right: "60%"
    }
}));
const CarouselDot = styled(Dot)(({theme}) => ({
    border: "none",
    background: theme.palette.grey[400],
    borderRadius: "50%",
    padding: 5,
    "&:not(:first-of-type)": {
        marginTop: 20
    },
    "&:disabled": {
        background: theme.palette.primary.light
    },
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));
const CarouselImage = styled("img")(({theme}) => ({
    position: "absolute",
    height: "52%",
    width: "78.5%",
    top: 0,
    left: 1,
    [theme.breakpoints.down("sm")]: {
        width: "65%",
        height: "42%"
    }
}));
const IconWrapper = styled(Box)(({theme}) => ({
    position: "absolute",
    right: "10%",
    display: "flex",
    bottom: "10%",
    zIndex: 4,
    [theme.breakpoints.down("lg")]: {
        right: "5%"
    },
    [theme.breakpoints.down("md")]: {
        left: "5%",
        flexDirection: "column",
        top: "55%"
    },
    [theme.breakpoints.down("sm")]: {
        top: "63%"
    }
}));
const BannerLink = styled("a")(({theme}) => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginLeft: 20
    },
    [theme.breakpoints.down("md")]: {
        "&:not(:first-of-type)": {
            marginLeft: 0,
            marginTop: 20
        }
    },
    [theme.breakpoints.down("sm")]: {
        "&:not(:first-of-type)": {
            marginTop: 10
        }
    }
}));
const BannerIcon = styled(Icon)(({theme}) => ({
    fontSize: 35,
    borderRadius: "50%",
    padding: 6,
    boxShadow: theme.customShadows.primary,
    cursor: "pointer",
    color: "#000",
    "&:hover": {
        color: theme.palette.primary.main
    },
    "&:not(:first-of-type)": {
        marginLeft: 20
    },
    [theme.breakpoints.down("md")]: {
        background: "#fff"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 25
    }
}));
const SideShape = styled(Box)(({theme}) => ({
    position: "absolute",
    height: "100%",
    width: "30%",
    right: 0,
    top: 0,
    background: "#f7f8ff",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
        display: "none"
    }
}));
const animationStyle = {
    transition: "opacity 0s ease-in"
};
const ShapeAContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "17%",
    left: "-20%",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
        top: "5%",
        left: "-3%"
    }
}));
const ShapeBContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: "-50%",
    left: "-10%",
    zIndex: -1,
    [theme.breakpoints.down("lg")]: {
        display: "none"
    }
}));
const ShapeCContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "-40%",
    right: "-12%",
    zIndex: -1,
    [theme.breakpoints.down("lg")]: {
        display: "none"
    }
}));
