import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {Svg} from "./blob-a";

function BlobC(props) {
    const theme = useTheme();
    const background = theme.palette.primary.dark;
    return (
        <Svg
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            {...props}>
            <path
                d="M393.5 341Q355 432 263 410T87.5 319Q4 250 82 171.5t168-78q90 .5 136 78.5t7.5 169z"
                fill={background}
            />
        </Svg>
    );
}

export default BlobC;
