import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {Svg} from "../../styled";

function ExchangeSvg(props) {
    const theme = useTheme();
    const color = theme.palette.primary.dark;
    return (
        <Svg
            height="432pt"
            fill={color}
            viewBox="-11 0 432 432.85845"
            width="432pt"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M102.828 210.906C46.368 210.906.43 164.973.43 108.508.43 52.039 46.367 6.105 102.828 6.105c56.465 0 102.402 45.934 102.402 102.403 0 56.465-45.937 102.398-102.402 102.398zm0-191.144c-48.937 0-88.746 39.812-88.746 88.746 0 48.933 39.809 88.746 88.746 88.746s88.746-39.813 88.746-88.746c0-48.934-39.808-88.746-88.746-88.746zm0 0" />
            <path d="M102.828 183.602c-41.406 0-75.094-33.688-75.094-75.094 0-41.41 33.688-75.094 75.094-75.094 41.41 0 75.094 33.684 75.094 75.094 0 41.406-33.684 75.094-75.094 75.094zm0-136.536c-33.875 0-61.437 27.559-61.437 61.442 0 33.879 27.562 61.437 61.437 61.437 33.879 0 61.442-27.558 61.442-61.437 0-33.883-27.563-61.442-61.442-61.442zm0 0" />
            <path d="M83.145 76.664c0-.566.375-.937.937-.937h23.746c13.516 0 19.899 6.75 19.899 16.984 0 7.227-3.38 11.351-7.415 13.516v.183c3.942 1.508 8.54 6.574 8.54 14.36 0 12.675-7.977 18.773-21.961 18.773H84.082c-.562 0-.937-.371-.937-.938zm24.402 25.621c5.633 0 9.101-3.379 9.101-8.355 0-5.067-3.468-8.446-9.101-8.446H94.78c-.375 0-.562.188-.562.563v15.68c0 .375.187.558.562.558zm-12.766 27.5h13.328c6.196 0 9.668-3.566 9.668-8.922 0-5.254-3.472-8.82-9.668-8.82H94.781c-.375 0-.562.187-.562.566v16.61c0 .379.187.566.562.566zm0 0" />
            <path d="M99.414 81.2a3.412 3.412 0 01-3.41-3.415v-6.824a3.412 3.412 0 116.824 0v6.824a3.413 3.413 0 01-3.414 3.414zm0 0M99.414 149.465a3.41 3.41 0 01-3.41-3.41v-6.828a3.412 3.412 0 116.824 0v6.828a3.412 3.412 0 01-3.414 3.41zm0 0M96.004 40.238h13.652v13.657H96.004zm0 0M96.004 163.121h13.652v13.652H96.004zm0 0M150.617 101.68h20.48v13.652h-20.48zm0 0M34.563 101.68h20.48v13.652h-20.48zm0 0M307.629 408.879c-56.465 0-102.399-45.934-102.399-102.399 0-56.468 45.934-102.402 102.399-102.402s102.398 45.934 102.398 102.402c0 56.465-45.933 102.399-102.398 102.399zm0-191.145c-48.938 0-88.746 39.813-88.746 88.746 0 48.934 39.808 88.747 88.746 88.747s88.746-39.813 88.746-88.747c0-48.933-39.809-88.746-88.746-88.746zm0 0" />
            <path d="M307.629 381.574c-41.406 0-75.094-33.687-75.094-75.094s33.688-75.093 75.094-75.093 75.094 33.687 75.094 75.093-33.688 75.094-75.094 75.094zm0-136.535c-33.879 0-61.442 27.559-61.442 61.441 0 33.88 27.563 61.438 61.442 61.438s61.441-27.559 61.441-61.438c0-33.882-27.562-61.44-61.441-61.44zm0 0" />
            <path d="M300.8 238.215h13.657v13.652h-13.656zm0 0M300.8 361.094h13.657v13.652h-13.656zm0 0M355.414 299.652h20.48v13.653h-20.48zm0 0M239.363 299.652h20.48v13.653h-20.48zm0 0M304.012 349.418v-10.34c-5.938-.105-12.082-1.945-15.668-4.508l2.457-6.86c3.687 2.454 9.011 4.4 14.746 4.4 7.27 0 12.187-4.2 12.187-10.032 0-5.637-3.996-9.113-11.57-12.187-10.45-4.094-16.898-8.809-16.898-17.715 0-8.5 6.043-14.946 15.464-16.485v-10.343h6.348v9.933c6.145.2 10.344 1.84 13.313 3.582l-2.559 6.758c-2.148-1.226-6.352-3.476-12.902-3.476-7.887 0-10.856 4.707-10.856 8.804 0 5.32 3.793 7.988 12.696 11.672 10.55 4.3 15.875 9.621 15.875 18.734 0 8.094-5.633 15.668-16.18 17.415v10.648zm0 0M389.55 210.906h-13.655c0-90.687-67.872-166.531-157.88-176.418l1.493-13.574c96.937 10.652 170.043 92.332 170.043 189.992zm0 0M198.402 402.055c-105.398 0-191.144-85.746-191.144-191.149H20.91c0 97.875 79.621 177.492 177.492 177.492zm0 0" />
            <path d="M233.684 71.332l-31.598-47.394L249.96 0l6.11 12.215-34.044 17.015 23.016 34.528zm0 0M180.164 432.86l-10.543-8.669 26.344-32.058-32.176-20.332 7.293-11.551 45.25 28.602zm0 0" />
        </Svg>
    );
}

export default ExchangeSvg;
