import React from "react";
import {Box, Container, Grid, useTheme} from "@mui/material";
import {
    Body,
    ColumnWrapper,
    Root,
    SideSubtitle,
    Title
} from "../styled";
import {styled} from "@mui/material/styles";
import {MotionInView, varFadeInUp} from "../animate";
import {Icon} from "@iconify/react";
import marketplaceLight from "../../../assets/images/marketplace.png";
import marketplaceDark from "../../../assets/images/marketplaceDark.png";
import useSettings from "../../../hooks/useSettings";

const Explore = () => {
    const theme = useTheme();
    const {themeMode} = useSettings();
    const isLight = themeMode === "light";
    return (
        <Root id="features" sx={{mt: {xs: "20px", md: "80px"}}}>
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid
                        item
                        container
                        alignItems="center"
                        direction="row-reverse">
                        <Grid item xs={12} md={6}>
                            <MotionInView variants={varFadeInUp}>
                                <ContentWrapper>
                                    <Title>The KoinExchanger Marketplace</Title>
                                    <SideSubtitle>
                                        KoinExchanger supports virtually almost all
                                        local currencies (FIAT) in the world
                                        with Bank Account listing for both local
                                        withdrawals and deposit, which is
                                        tradeable with the supported
                                        Cryptocurrencies in the system. Exchange
                                        rates for each local currencies can both
                                        be manually set or automatically sourced
                                        from a trusted exchange rates provider.
                                    </SideSubtitle>
                                </ContentWrapper>
                            </MotionInView>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MotionInView variants={varFadeInUp}>
                                <ImageWrapper>
                                    {isLight ? (
                                        <Image
                                            src={marketplaceLight}
                                            alt="pc-tab-phone"
                                        />
                                    ) : (
                                        <Image
                                            src={marketplaceDark}
                                            alt="pc-tab-phone"
                                        />
                                    )}
                                </ImageWrapper>
                            </MotionInView>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <MotionInView variants={varFadeInUp}>
                            <ItemContainer>
                                {[
                                    "Safe & Secure Transactions",
                                    "Easily Customisable",
                                    "Multiple Payment Gateway",
                                    "KYC Verification"
                                ].map((items, i) => (
                                    <ItemWrapper key={i}>
                                        <IconWrapper>
                                            <ExploreIcon icon="typcn:tick" />
                                        </IconWrapper>
                                        <Body
                                            sx={{
                                                color: theme.palette.text
                                                    .primary
                                            }}>
                                            {items}
                                        </Body>
                                    </ItemWrapper>
                                ))}
                            </ItemContainer>
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    );
};

export default Explore;

const ContentWrapper = styled(ColumnWrapper)(({theme}) => ({
    padding: "100px 0px",
    maxWidth: 600,
    [theme.breakpoints.down("md")]: {
        padding: "40px 0",
        maxWidth: "100%"
    }
}));
const ItemContainer = styled(Box)(({theme}) => ({
    display: "flex",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column"
    }
}));
const ItemWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: 25,
    borderRadius: 4,
    border: `2px solid ${theme.palette.primary.lighter}`,
    "&:not(:first-of-type)": {
        marginLeft: 20
    },
    [theme.breakpoints.down("md")]: {
        "&:not(:first-of-type)": {
            marginLeft: 0
        },
        marginBottom: 30
    }
}));
const IconWrapper = styled(Box)(({theme}) => ({
    borderRadius: "50%",
    width: 35,
    height: 35,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,
    border: `2px solid ${theme.palette.primary.main}`,
    "&::before": {
        content: "''",
        position: "absolute",
        background: theme.palette.primary.light,
        left: 0,
        top: 0,
        borderRadius: "inherit",
        width: "100%",
        height: "100%",
        transform: "scale(1.8)",
        opacity: 0.4
    }
}));
const ExploreIcon = styled(Icon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 30
}));
const ImageWrapper = styled(Box)(({theme}) => ({
    maxWidth: "100%",
    marginRight: 50,
    [theme.breakpoints.down("md")]: {
        margin: "0 auto",
        paddingBottom: 40
    }
}));
const Image = styled("img")(({theme}) => ({
    width: "100%"
}));
