import React from "react";
import {
    Body,
    CentreWrapper,
    Desc,
    Header,
    ImageAnimation,
    Root,
    RowWrapper,
    Subtitle,
    Title
} from "../styled";
import {Box, Container, Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
    MotionInView,
    varFadeInLeft,
    varFadeInRight,
    varFadeInUp
} from "../animate";
import aboutLightImg from "../../../assets/images/dashboardLight.png";
import aboutDarkImg from "../../../assets/images/dashboardDark.png";
import ExchangeSvg from "./component/exchange";
import WalletSvg from "./component/wallet";
import GiftcardSvg from "./component/GiftcardSvg";
import useSettings from "../../../hooks/useSettings";

const About = () => {
    const {themeMode} = useSettings();
    const isLight = themeMode === "light";
    return (
        <Root id="about">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <ContentGrid item>
                        <MotionInView variants={varFadeInUp}>
                            <CentreWrapper>
                                <Desc>What is KoinExchanger?</Desc>
                                <Title>About Us</Title>
                                <Subtitle>
                                    We’ve built a platform to buy and sell
                                    digital assets
                                </Subtitle>
                            </CentreWrapper>
                        </MotionInView>
                    </ContentGrid>
                    <Grid item container>
                        <Grid item xs={12} md={6}>
                            <MotionInView variants={varFadeInLeft}>
                                <ImageWrapper>
                                    <ImageAnimation />
                                    {isLight ? (
                                        <Image src={aboutLightImg} />
                                    ) : (
                                        <Image src={aboutDarkImg} />
                                    )}
                                </ImageWrapper>
                            </MotionInView>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MotionInView variants={varFadeInRight}>
                                <Box>
                                    <Header>
                                        We’ve built a platform to buy and sell
                                        digital assets
                                    </Header>
                                    <Body>
                                        With support for multiple
                                        Cryptocurrencies, you are able to host
                                        buy & sell with direct bank transfer,
                                        credit cards or any other supported
                                        payment gateways.
                                    </Body>
                                    <Box sx={{mt: "20px"}}>
                                        <RowWrapper>
                                            <IconWrapper>
                                                <ExchangeSvg />
                                            </IconWrapper>
                                            <Body>
                                                We supports buying and selling
                                                of Cryptocurrencies with direct
                                                bank transfer or payment
                                                gateways.
                                            </Body>
                                        </RowWrapper>
                                        <RowWrapper>
                                            <IconWrapper>
                                                <WalletSvg />
                                            </IconWrapper>{" "}
                                            <Body>
                                                Using custodial wallet
                                                providers, KoinExchanger provides
                                                wallet functionalities to
                                                registered users with unique
                                                receiving addresses
                                            </Body>
                                        </RowWrapper>
                                        <RowWrapper>
                                            <IconWrapper>
                                                <GiftcardSvg />
                                            </IconWrapper>
                                            <Body>
                                                KoinExchanger supports giftcard
                                                marketplace listing, with
                                                checkout and payment fulfilment
                                                using the existing payment
                                                account of users.
                                            </Body>
                                        </RowWrapper>
                                    </Box>
                                </Box>
                            </MotionInView>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    );
};

export default About;

const ContentGrid = styled(Grid)(({theme}) => ({
    marginBottom: 100,
    [theme.breakpoints.down("md")]: {
        marginBottom: "-15%"
    }
}));
const ImageWrapper = styled(Box)(({theme}) => ({
    maxWidth: 600,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        margin: "100px 0 60px"
    },
    [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 40px"
    }
}));
const Image = styled("img")(({theme}) => ({
    width: "90%",
    boxShadow: theme.customShadows.z12,
    borderRadius: theme.shape.borderRadiusSm,
    marginLeft: "-20%",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        width: "80%",
        marginTop: 100
    },
    [theme.breakpoints.down("sm")]: {
        width: "80%",
        marginTop: 140
    }
}));
const IconWrapper = styled(Box)(() => ({
    display: "flex"
}));
