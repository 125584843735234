import React from "react";
import {Box, Card, Container, Grid, Typography} from "@mui/material";
import {
    CenterWrapper,
    ColumnWrapper,
    CentreWrapper,
    Desc,
    Root,
    Title,
    ContainedButton,
    PageLink,
    SideSubtitle
} from "../styled";
import {styled} from "@mui/material/styles";
import EasySvg from "./component/easy";
import SecureSvg from "./component/secure";
import RocketSvg from "./component/fast";
import {MotionInView, varFadeInUp, varFadeInDown} from "../animate";

const Features = () => {
    return (
        <Root id="features">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <MotionInView variants={varFadeInUp}>
                                <FeatureContent>
                                    <Desc>Why choose us?</Desc>
                                    <Title>Our Best Features</Title>
                                    <SideSubtitle>
                                        The KoinExchanger Team is working tirelessly
                                        to ensure you have wonderful experience
                                        while exploring the ecosystem.
                                    </SideSubtitle>
                                </FeatureContent>
                            </MotionInView>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <MotionInView variants={varFadeInDown}>
                                <FeatureCardContainer>
                                    <FeaturesCard>
                                        <FeaturesWrapper>
                                            <CenterWrapper>
                                                <EasySvg />
                                            </CenterWrapper>
                                            <FeaturesHeader>
                                                Easy to Use
                                            </FeaturesHeader>
                                            <FeaturesSubheader>
                                                We have built a user friendly
                                                system for the convenience of
                                                our users. Explore our system to
                                                see just how easy it is to use
                                                and understand KoinExchanger.
                                            </FeaturesSubheader>
                                        </FeaturesWrapper>
                                    </FeaturesCard>
                                    <MidFeaturesCard>
                                        <FeaturesWrapper>
                                            <CenterWrapper>
                                                <SecureSvg />
                                            </CenterWrapper>
                                            <FeaturesHeader>
                                                Secure Transactions
                                            </FeaturesHeader>
                                            <FeaturesSubheader>
                                                Our team has taken alot of
                                                security precautions to ensure
                                                that our system is protected
                                                from all sorts of security
                                                threats within the system.
                                            </FeaturesSubheader>
                                        </FeaturesWrapper>
                                    </MidFeaturesCard>
                                    <FeaturesCard>
                                        <FeaturesWrapper>
                                            <CenterWrapper>
                                                <RocketSvg />
                                            </CenterWrapper>
                                            <FeaturesHeader>
                                                Super Fast
                                            </FeaturesHeader>
                                            <FeaturesSubheader>
                                                Our system performance have been
                                                greatly enhanced and optimised
                                                to ensure that all transactions
                                                goes on swiftly in and out of
                                                the system.
                                            </FeaturesSubheader>
                                        </FeaturesWrapper>
                                    </FeaturesCard>
                                </FeatureCardContainer>
                            </MotionInView>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CenterWrapper sx={{marginTop: "50px"}}>
                            <PageLink href="/auth/login">
                                <ContainedButton>
                                    Create a wallet
                                </ContainedButton>
                            </PageLink>
                        </CenterWrapper>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    );
};

export default Features;
const FeatureContent = styled(CentreWrapper)(({theme}) => ({
    alignItems: "flex-start",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
        marginTop: 0,
        alignItems: "center"
    }
}));
const FeatureCardContainer = styled(Box)(({theme}) => ({
    display: "flex",
    marginLeft: 50,
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        marginTop: 0,
        flexDirection: "column"
    }
}));
const FeaturesWrapper = styled(ColumnWrapper)(({theme}) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        paddingBottom: 50
    },
    [theme.breakpoints.down("sm")]: {
        paddingBottom: 30
    }
}));
const FeaturesCard = styled(Card)(({theme}) => ({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "30px 20px",
    height: "fit-content",
    boxShadow: theme.customShadows.z12,
    "&:not(:first-of-type)": {
        marginLeft: 20
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
        flexDirection: "column",
        padding: 40,
        "&:not(:first-of-type)": {
            marginLeft: 0,
            marginTop: 20
        }
    },
    "&:hover": {
        cursor: "pointer",
        transform: "translateY(-10px)",
        boxShadow: theme.customShadows.z12,
        transition: "0.4s all ease"
    }
}));
const MidFeaturesCard = styled(FeaturesCard)(({theme}) => ({
    marginTop: "14%",
    [theme.breakpoints.down("md")]: {
        marginTop: 0
    }
}));
const FeaturesHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    paddingTop: 20,
    color: theme.palette.text.primary,
    textAlign: "center"
}));
const FeaturesSubheader = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    paddingTop: 5,
    textAlign: "center"
}));
