import React from "react";
import {
    Body,
    CentreWrapper,
    ColumnWrapper,
    Desc,
    Image,
    ImageContainer,
    Root,
    Subtitle,
    Title
} from "../styled";
import {Container, Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import pcSignupLight from "../../../assets/images/signupPcLight.png";
import pcSignupDark from "../../../assets/images/signupPcDark.png";
import signupLight from "../../../assets/images/signupLight.png";
import signupDark from "../../../assets/images/signupDark.png";
import useSettings from "../../../hooks/useSettings";

const Register = () => {
    const {themeMode} = useSettings();
    const isLight = themeMode === "light";
    return (
        <Root>
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <CentreWrapper>
                            <Desc>Joining KoinExchanger</Desc>
                            <Title>Get started now</Title>
                            <Subtitle>
                                Can’t wait to try KoinExchanger? You don’t have to!
                                Getting started is a matter of minutes.
                            </Subtitle>
                        </CentreWrapper>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={8}>
                            <RegisterImageContainer>
                                {isLight ? (
                                    <FirstImage src={pcSignupLight} />
                                ) : (
                                    <FirstImage src={pcSignupDark} />
                                )}
                                {isLight ? (
                                    <SecondImage src={signupLight} />
                                ) : (
                                    <SecondImage src={signupDark} />
                                )}
                            </RegisterImageContainer>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <RegisterListContainer>
                                <ListWrapper>
                                    <Subtitle sx={{textAlign: "left"}}>
                                        1. SignUp
                                    </Subtitle>
                                    <Body>
                                        The first step is to create your
                                        account.
                                        <br /> We’ll guide you through the
                                        process.
                                    </Body>
                                </ListWrapper>
                                <ListWrapper>
                                    <Subtitle sx={{textAlign: "left"}}>
                                        2. Deposit Money
                                    </Subtitle>
                                    <Body>
                                        Link to your bank account.
                                        <br /> Buy crypto through our partners
                                        or transfer in funds.
                                    </Body>
                                </ListWrapper>
                                <ListWrapper>
                                    <Subtitle sx={{textAlign: "left"}}>
                                        3. Explore the ecosystem
                                    </Subtitle>
                                    <Body>
                                        See how easy it is to trade, pay and
                                        invest with KoinExchanger!.
                                    </Body>
                                </ListWrapper>
                            </RegisterListContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    );
};

export default Register;

const RegisterImageContainer = styled(ImageContainer)(({theme}) => ({
    margin: "50px 0px",
    [theme.breakpoints.down("md")]: {
        margin: "50px 0px"
    }
}));
const RegisterListContainer = styled(ColumnWrapper)(({theme}) => ({
    margin: "50px 0px 0px 50px",
    [theme.breakpoints.down("md")]: {
        margin: "0"
    }
}));
const FirstImage = styled(Image)(({theme}) => ({
    boxShadow: theme.customShadows.z12,
    borderRadius: theme.shape.borderRadiusSm,
    width: "80%",
    height: 350,
    [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%"
    }
}));
const SecondImage = styled(Image)(({theme}) => ({
    boxShadow: theme.customShadows.z12,
    borderRadius: theme.shape.borderRadiusSm,
    position: "absolute",
    height: 350,
    width: 250,
    right: "1%",
    top: "40%",
    [theme.breakpoints.down("md")]: {
        display: "none"
    }
}));
const ListWrapper = styled(ColumnWrapper)(({theme}) => ({
    marginBottom: 20,
    borderRadius: theme.shape.borderRadiusSm,
    padding: 20,
    cursor: "pointer",
    "&:first-of-type": {
        boxShadow: theme.customShadows.z12
    },
    "&:hover": {
        boxShadow: theme.customShadows.z12,
        transition: "0.4s all ease"
    }
}));
