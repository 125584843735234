import React from "react";
import {ColumnWrapper, Desc, Header, Root, Subheader} from "../styled";
import {Box, CircularProgress, Container, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import Badge from "./component/badge";
import {keyframes} from "@emotion/react";
import {MotionInView, varFadeInDown} from "../animate";
import {coins} from "./data";
import useCoincapApi from "./../../../hooks/useCoincapApi";

const PriceSlide = () => {
    return (
        <Root
            sx={{
                background: (theme) => theme.palette.grey["100"],
                position: "relative"
            }}>
            <Container maxWidth="lg">
                <Stack>
                    <MotionInView variants={varFadeInDown}>
                        <SlideWrapper>
                            <Stack sx={{width: "100%"}}>
                                <Desc>Cryptocurrency list</Desc>
                                <Header
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.grey[800]
                                    }}>
                                    Today&apos;s global Price
                                </Header>
                            </Stack>
                            <Subheader sx={{textAlign: "left"}}>
                                Tap into the cryptocurrency market to buy, hold
                                and sell Bitcoin, Ethereum, Shiba Inu and more,
                                24/7, with our exchange.
                            </Subheader>
                        </SlideWrapper>
                    </MotionInView>
                    <SlideBox>
                        {coins.map((coin) => {
                            return (
                                <CryptoContainer coin={coin} key={coin.id} />
                            );
                        })}
                    </SlideBox>
                </Stack>
            </Container>
        </Root>
    );
};

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
});
const CryptoContainer = ({coin}) => {
    const [data, loading] = useCoincapApi(coin.id);

    return (
        <CryptoBox key={crypto.id}>
            {loading ? (
                <Box
                    sx={{
                        width: "inherit",
                        height: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                        }}>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <ImageWrapper>
                                <CryptoImage
                                    src={coin.image}
                                    alt="crypto-img"
                                />
                            </ImageWrapper>
                            <ColumnWrapper>
                                <CryptoText>{data.name}</CryptoText>
                                <CryptoText
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.grey["500"],
                                        fontSize: "0.7rem"
                                    }}></CryptoText>
                            </ColumnWrapper>
                        </Box>
                        <ColumnWrapper sx={{alignItems: "flex-end"}}>
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <CryptoText
                                    sx={{
                                        fontSize: "1rem",
                                        marginRight: "-4px"
                                    }}>
                                    {"#" + data.rank}
                                </CryptoText>
                                <Badge />
                            </Box>
                            <CryptoText
                                sx={{
                                    color: (theme) => theme.palette.grey["500"],
                                    fontSize: "0.7rem"
                                }}>
                                Market Cap Rank
                            </CryptoText>
                        </ColumnWrapper>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            marginTop: {xs: "10px", md: "30px"}
                        }}>
                        <ColumnWrapper>
                            <CryptoText>
                                {formatter.format(data.marketCap)}
                            </CryptoText>
                            <CryptoText
                                sx={{
                                    color: (theme) => theme.palette.grey["500"],
                                    fontSize: "0.7rem"
                                }}>
                                Market Cap
                            </CryptoText>
                        </ColumnWrapper>
                        <ColumnWrapper sx={{alignItems: "flex-end"}}>
                            {data.percentChange?.includes("-") ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        color: (theme) =>
                                            theme.palette.error.main
                                    }}>
                                    <Icon icon="eva:arrow-down-fill" />
                                    <CryptoText
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.error.main
                                        }}>
                                        {Number(data.percentChange).toFixed(2) +
                                            "%"}
                                    </CryptoText>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        color: (theme) =>
                                            theme.palette.success.main
                                    }}>
                                    <Icon icon="eva:arrow-up-fill" />
                                    <CryptoText
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.success.main
                                        }}>
                                        {"+" +
                                            Number(data.percentChange).toFixed(
                                                2
                                            ) +
                                            "%"}
                                    </CryptoText>
                                </Box>
                            )}
                            <CryptoText
                                sx={{
                                    color: (theme) => theme.palette.grey["500"],
                                    fontSize: "0.7rem"
                                }}>
                                Price Change(24h)
                            </CryptoText>
                        </ColumnWrapper>
                    </Box>
                </Box>
            )}
        </CryptoBox>
    );
};

const SlideWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "20%",
        alignItems: "none"
    },
    [theme.breakpoints.down("sm")]: {
        marginBottom: "30%"
    }
}));
const CryptoBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    width: 350,
    height: 166.7,
    padding: "30px 20px",
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadiusMd,
    boxShadow: theme.shadows[10],
    "&:not(:last-child)": {
        marginRight: 20
    },
    [theme.breakpoints.down("md")]: {
        width: 300,
        padding: "20px 10px"
    }
}));
const CryptoText = styled(Typography)(({theme}) => ({
    fontSize: "0.8rem",
    fontWeight: 700,
    margin: "0px 10px",
    color: theme.palette.primary.darker
}));
const CryptoImage = styled("img")(() => ({
    width: 20,
    height: 20
}));
const ImageWrapper = styled(Box)(({theme}) => ({
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    background: theme.palette.grey["100"]
}));
const SlideBox = styled(Box)(({theme}) => ({
    display: "flex",
    position: "absolute",
    top: "70%",
    zIndex: 3,
    animation: `${linear1} 12s linear 3s infinite alternate`,
    "&:hover": {
        cursor: "pointer",
        animationPlayState: "paused"
    },
    [theme.breakpoints.down("lg")]: {
        animation: `${linear2} 12s linear 3s infinite alternate`
    },
    [theme.breakpoints.down("md")]: {
        animation: `${linear3} 12s linear 3s infinite alternate`
    }
}));

const linear1 = keyframes`
  0% {
    transform: translate(20%, 0)
  }
  100% {
    transform: translate(-71%, 0)
  }
`;
const linear2 = keyframes`
  0% {
    transform: translate(0%, 0)
  }
  100% {
    transform: translate(-79%, 0)
  }
`;
const linear3 = keyframes`
  0% {
    transform: translate(0%, 0)
  }
  100% {
    transform: translate(-89%, 0)
  }
`;

export default PriceSlide;
