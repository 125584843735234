import {styled} from "@mui/material/styles";
import {Box, Button} from "@mui/material";
import {keyframes} from "@emotion/react";

export const Root = styled(Box)(({theme}) => ({
    width: "100%",
    padding: "50px 0px",
    position: "relative",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
        padding: "40px 0"
    }
}));

export const Desc = styled(Box)(({theme}) => ({
    ...theme.typography.h6,
    color: theme.palette.primary.light,
    marginBottom: 10
}));

export const Title = styled(Box)(({theme}) => ({
    ...theme.typography.h3,
    marginBottom: 20,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));

export const Subtitle = styled(Box)(({theme}) => ({
    ...theme.typography.h5,
    textAlign: "center",
    fontWeight: 400,
    marginBottom: 20
}));

export const Subheader = styled(Box)(({theme}) => ({
    ...theme.typography.h6,
    fontWeight: 400,
    marginBottom: 20,
    color: theme.palette.grey["500"],
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));

export const SideSubtitle = styled(Subtitle)(({theme}) => ({
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));

export const Header = styled(Box)(({theme}) => ({
    ...theme.typography.h3,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
        marginTop: 50
    }
}));

export const Body = styled(Box)(({theme}) => ({
    ...theme.typography.body1
}));

export const CentreWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}));

export const CenterWrapper = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center"
}));

export const ColumnWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column"
}));

export const RowWrapper = styled(Box)(() => ({
    display: "flex",
    marginBottom: 10
}));

export const ContainedButton = styled(Button)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 48,
    padding: "8px 22px",
    letterSpacing: 1.1,
    textTransform: "uppercase",
    background: theme.palette.primary.main,
    color: "#fff",
    fontSize: 14,
    borderRadius: theme.shape.borderRadius,
    fontWeight: 500,
    boxShadow: theme.customShadows.primary,
    "&:hover": {
        background: theme.palette.primary.dark
    }
}));

export const OutlinedButton = styled(ContainedButton)(({theme}) => ({
    background: "transparent",
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    boxShadow: "none",
    "&:hover": {
        background: theme.palette.primary.main,
        color: "#fff"
    }
}));

export const Svg = styled("svg")(({theme}) => ({
    width: 40,
    height: 40,
    fill: theme.palette.primary.main,
    marginRight: 15
}));

export const ImageContainer = styled(Box)(() => ({
    maxWidth: "100%",
    position: "relative",
    width: "100%"
}));

export const Image = styled("img")(() => ({
    width: "100%"
}));

export const ImageAnimation = styled(Box)(({theme}) => ({
    position: "absolute",
    height: 500,
    width: 500,
    left: "40%",
    zIndex: 1,
    top: "50%",
    "&::before, &::after": {
        position: "absolute",
        content: "''",
        border: "4px solid rgba(151, 175, 213, 0.3)",
        borderRadius: "50%",
        boxSizing: "border-box"
    },
    "&::before": {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderTopColor: theme.palette.primary.dark,
        borderRightColor: theme.palette.primary.dark,
        animation: `${rotate} 15s linear infinite 0s;`
    },
    "&::after": {
        top: 5,
        left: 5,
        bottom: 85,
        right: 85,
        borderTopColor: theme.palette.primary.main,
        borderBottomColor: theme.palette.primary.main,
        animation: `${reverseRotate} 10s linear infinite 0s;`
    },
    [theme.breakpoints.down("lg")]: {
        height: 400,
        width: 400
    },
    [theme.breakpoints.down("md")]: {
        left: "50%",
        height: 500,
        width: 500,
        top: "60%"
    },
    [theme.breakpoints.down("sm")]: {
        height: 320,
        width: 320,
        top: "67%"
    }
}));

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(
            0deg)
  }
  to {
    transform: translate(-50%, -50%) rotate(
            360deg)
  }
`;
const reverseRotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(
            360deg)
  }
  to {
    transform: translate(-50%, -50%) rotate(
            0deg)
  }
`;
export const PageLink = styled("a")(() => ({
    textDecoration: "none"
}));
